$(document).ready(function () {
	$('.header__book').on('click', function () {
		initBooking();
		$(window).trigger('resize'); // rejig the equalizer for the date fields
	});

	initTableBooking();
	
	$('.booking__tabs-content-room-why-book').accordiom({
		showFirstItem: false
	});
});


function initTableBooking() {
	$('a[href="#book-restaurant"], a[href="#book-afternoontea"], .tablebooking').on('click', function () {
		// Dynamically load the gallery slider into a reveal popup
		var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-image"><iframe src="{{src}}" frameborder="0" style="width: 100%; max-width: 450px; height: 740px; margin: 0 auto; display: block;"></iframe></div></div>';

		if ( $('body').hasClass('theme-prestonfield') ) {
			if ( $(this).attr('href') == '#book-restaurant' ) {
				modal = modal.replace('{{src}}', 'https://booking.resdiary.com/widget/Standard/Prestonfield/27991?includeJquery=true');
			} else if ( $(this).attr('href') == '#book-afternoontea' ) {
				modal = modal.replace('{{src}}', 'https://booking.resdiary.com/widget/Standard/Prestonfield/27959?includeJquery=true');
			}
		} else if ( $('body').hasClass('theme-witchery') ) {
			if ( ($(this).attr('href') == '#book-restaurant') || $(this).hasClass('tablebooking') ) {
				modal = modal.replace('{{src}}', 'https://booking.resdiary.com/widget/Standard/TheWitchery22/27749');
			}
		}



		var $modal = modal;

		$('body').append($modal);

		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});

		$('#gallery__modal').foundation('open');

		return false;
	});
}


function initBooking() {
	//$('#booking').foundation('open');

	// Set the hotel specifics
	if ( $('body').hasClass('theme-prestonfield') ) {
		$('.booking input[name="hotel"]').val('11547');
		$('.booking input[name="chain"]').val('25766');
	} else if ( $('body').hasClass('theme-witchery') ) {
		$('.booking input[name="hotel"]').val('66353');
		$('.booking input[name="chain"]').val('25766');
	}


	// Only load 3rd party iframes when the booking widget is toggled
	$('.booking iframe').each(function () {
		if ( typeof $(this).data('src') !== 'undefined' ) {
			$(this).attr('src', $(this).data('src'));
		}
	});



	// Open the booking widget and switch to the book table tab
	/*$('a[href="#bookTable"]').on('click', function () {
		$('#booking').foundation('open');
		$('#book-table-label').trigger('click');
		loadIframes();
		return false;
	});*/




	// Hide any open date pickers when changing tab
	$('#booking-tabs').on('change.zf.tabs', function () {
		$('.datepicker-container').addClass('datepicker-hide');
	});


	var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
	var dayNames   = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];

	var bookingContainer = $('.booking__tabs-content-room form');

	var nowTemp = new Date();
	// Don't allow bookings until the hotel opens
	if (nowTemp < new Date('2021-04-15') ) {
		nowTemp = new Date('2021-04-15');
	}


	var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);

	var $checkInField = bookingContainer.find('.check-in');
	var $checkOutField = bookingContainer.find('.check-out');

	$checkInField.on('hide.datepicker', function () {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');
		var dayAfterCheckIn = new Date(checkInDate);
			dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

		// Ensure checkout comes after checkin.
		// Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
		if ( checkInDate >= checkOutDate ) {
			$checkOutField.datepicker('setDate', dayAfterCheckIn);
		}

		$checkOutField.datepicker('setStartDate', dayAfterCheckIn);

		$checkOutField[0].focus();
	});

	var checkin = $checkInField.datepicker({
		format: 'dd',
		autoPick: true,
		autoHide: true,
		date: null,
		startDate: nowTemp,
		pick: function (e) {
			// User-facing date fields
			$('.check-in-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
			$('.check-in-day').text( dayNames[e.date.getDay()] );

			// Formatted date for the booking engine
			$('input[name="arrive"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + pad( e.date.getDate(), 2 ) )
		}
	});

	var checkout = $checkOutField.datepicker({
		format: 'dd',
		autoPick: true,
		autoHide: true,
		date: null,
		startDate: tomorrow,
		pick: function (e) {
			// User-facing date fields
			$('.check-out-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
			$('.check-out-day').text( dayNames[e.date.getDay()] );

			// Formatted date for the booking engine
			$('input[name="depart"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + pad( e.date.getDate(), 2 )  );
		}
	});


	$('.booking select').selectric({
		onOpen: function() {
			$checkOutField.datepicker('hide');
		}
	});


	


	$('#booking__picker-select').on('change', function () {
		$('.tabs-panel').removeClass('is-active');
		$('#' + $(this).val()).addClass('is-active');
	});
}
