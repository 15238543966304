// This file contains customised Architecture settings.
// Refer to bower_components/Architecture-Blueprint/markup/js/architecture.js for full list of options

var projectSettings = {
	slider: {
		speed: 6500,
		animationSpeed: 1500,
		arrows: true,
		pagination: true,
		paginationArrows: true
	},
	inlineSVG: [
		'.header__call img',
		'.footer__social img',
		'.home-witchery-item__social img'
	]
};

if ( $('body').hasClass('theme-prestonfield') ) {
	projectSettings.mapStyle = [{featureType:"all",elementType:"all",stylers:[{hue:"#ffaa00"},{saturation:"-33"},{lightness:"10"}]},{featureType:"administrative.locality",elementType:"labels.text.fill",stylers:[{color:"#9c5e18"},{visibility:"on"}]},{featureType:"landscape.natural.terrain",elementType:"geometry",stylers:[{visibility:"simplified"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"on"}]},{featureType:"poi",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"road.highway",elementType:"geometry",stylers:[{visibility:"simplified"}]},{featureType:"road.highway",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"road.arterial",elementType:"geometry",stylers:[{visibility:"simplified"}]},{featureType:"transit.line",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"geometry.fill",stylers:[{saturation:"-23"},{gamma:"2.01"},{color:"#d4e6ea"}]},{featureType:"water",elementType:"geometry.stroke",stylers:[{saturation:"-14"}]}];
} else if ( $('body').hasClass('theme-witchery') ) {
	projectSettings.mapStyle = [{featureType:"all",elementType:"all",stylers:[{hue:"#ffaa00"},{saturation:"-33"},{lightness:"10"}]},{featureType:"administrative.locality",elementType:"labels.text.fill",stylers:[{color:"#9c5e18"},{visibility:"on"}]},{featureType:"landscape.natural.terrain",elementType:"geometry",stylers:[{visibility:"simplified"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"on"}]},{featureType:"poi",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"road.highway",elementType:"geometry",stylers:[{visibility:"simplified"}]},{featureType:"road.highway",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"road.arterial",elementType:"geometry",stylers:[{visibility:"simplified"}]},{featureType:"transit.line",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"geometry.fill",stylers:[{saturation:"-23"},{gamma:"2.01"},{color:"#d4e6ea"}]},{featureType:"water",elementType:"geometry.stroke",stylers:[{saturation:"-14"}]}];
}
